import axios, { AxiosError, AxiosResponse, AxiosStatic } from "axios";
import Keycloak, { KeycloakInstance } from "keycloak-js";
import { ref, Ref, readonly, watch } from "vue";
import { initialUserState, State } from "../store";
import { Store } from "vuex";
import { notification } from "ant-design-vue";
import { UserState } from "../../types";
import { useI18n } from "vue-i18n";

interface KcConfig {
  url: string;
  realm: string;
  clientId: string;
}

const roles = {
  PATIENT: "PATIENT",
  DOCTOR: "DOCTOR",
};

const _keycloak: Ref<KeycloakInstance | undefined> = ref(undefined);
const _http: Ref<AxiosStatic | undefined> = ref(undefined);
const _t: Ref<ReturnType<typeof useI18n>["t"] | undefined> = ref(undefined);
const _store: Ref<Store<State> | undefined> = ref(undefined);
const _isAuthenticated = ref(false);
const _kcInitialized = ref(false);

export const useKeycloak = () => {
  const createKeycloak = (
    kcConfig: KcConfig,
    http: AxiosStatic,
    t: ReturnType<typeof useI18n>["t"],
    store: Store<State>
  ) => {
    _http.value = http;
    _t.value = t;
    _store.value = store;
    _keycloak.value = Keycloak(kcConfig);
  };

  const setupUser = async (
    userState: UserState = initialUserState,
    keycloak: KeycloakInstance,
    http: AxiosStatic,
    store: Store<State>
  ) => {
    try {
      const profileRes = await http.get("patient-portal/api/patient-profile");
      store.commit("setUser", userState);
      store.commit("setNfzActive", profileRes.data.nfzActive);
      store.commit("setUserAgreements", profileRes.data.termsAgreement);
      store.commit(
        "setMakeDeclarationPopupStatus",
        profileRes.data.popups.makeDeclaration
      );
      store.commit(
        "setChangeDeclarationFacilityStatus",
        profileRes.data.popups.changeDeclarationFacility
      );
    } catch {
      notification.open({
        message: _t.value ? _t.value("ERROR.4312") : "",
        class: "error",
      });
      keycloak.logout();
    }
  };

  const updateToken = (token: string, http: AxiosStatic) => {
    http.defaults.headers.Authorization = `Bearer ${token}`;
  };

  const clearKeycloak = () => {
    _store.value?.commit("resetUser");
    updateToken("", _http.value as AxiosStatic);
    _keycloak.value?.clearToken();
    const cookies = document.cookie.split(";");
    _isAuthenticated.value = false;

    for (let i = 0; i < cookies.length; i++) {
      const cookie = cookies[i];
      const eqPos = cookie.indexOf("=");
      const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
      document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
    }
    localStorage.clear();
    sessionStorage.clear();
  };

  const loginUser = async () => await _keycloak.value?.login();
  const logoutUser = async (
    authRedirectUrl: string | undefined = undefined
  ) => {
    clearKeycloak();
    if (authRedirectUrl && authRedirectUrl.length > 0) {
      return await _keycloak.value?.logout({ redirectUri: authRedirectUrl });
    } else {
      return await _keycloak.value?.logout();
    }
  };

  const onTokenRefresh = async (
    keycloak: KeycloakInstance,
    http: AxiosStatic
  ) => {
    keycloak.onTokenExpired = () => {
      onTokenRefresh(keycloak, http);
    };
    await keycloak
      .updateToken(5)
      .then(async () => {
        updateToken(keycloak.token as string, http);
      })
      .catch(async () => {
        await logoutUser();
        notification.open({
          message: _t.value ? _t.value("ERROR.4311") : "",
          class: "error",
        });
      });
  };

  const initKeycloak = async () => {
    if (_keycloak.value && _http.value) {
      _keycloak.value.onTokenExpired = () => {
        onTokenRefresh(
          _keycloak.value as KeycloakInstance,
          _http.value as AxiosStatic
        );
      };
      return _keycloak.value
        .init({
          onLoad: "check-sso",
        })
        .then(async (authenticated: boolean) => {
          if (authenticated) {
            const forceLogout = localStorage.getItem("forceLogout")
              ? JSON.parse(localStorage.getItem("forceLogout") as string)
              : "";
            const isLoggingIn = sessionStorage.getItem("isLoggingIn")
              ? JSON.parse(sessionStorage.getItem("isLoggingIn") as string)
              : "";
            if (forceLogout && !isLoggingIn) {
              await logoutUser();
              return;
            }
            updateToken(
              _keycloak.value?.token as string,
              _http.value as AxiosStatic
            );
            await setupUser(
              {
                ...initialUserState,
                roles: _keycloak.value?.tokenParsed?.realm_access?.roles.filter(
                  (rule: string) => {
                    return rule == roles.PATIENT || rule == roles.DOCTOR;
                  }
                ) as string[],
              },
              _keycloak.value as KeycloakInstance,
              _http.value as AxiosStatic,
              _store.value as Store<State>
            );
            _isAuthenticated.value = authenticated;
            _kcInitialized.value = true;
          }
        })
        .catch(async () => {
          notification.open({
            message: _t.value ? _t.value("ERROR.4311") : "",
            class: "error",
          });
          await logoutUser();
        });
    } else {
      notification.open({
        message: _t.value ? _t.value("ERROR.4982") : "",
        class: "error",
      });
    }
  };

  _http.value?.interceptors.response.use(
    (response: AxiosResponse) => {
      return response;
    },
    async (error: AxiosError) => {
      if (axios.isAxiosError(error)) {
        if (error.response?.status === 401) {
          notification.open({
            message: _t.value ? _t.value("ERROR.4311") : "",
            class: "error",
          });
          await logoutUser();
        }
      }
      return error;
    }
  );

  watch(
    () => _keycloak.value as KeycloakInstance,
    (nv) => {
      if (!nv?.authenticated) {
        _isAuthenticated.value = false;
      }
    },
    { deep: true }
  );

  return {
    initKeycloak,
    createKeycloak,
    loginUser,
    logoutUser,
    clearKeycloak,
    isAuthenticated: readonly(_isAuthenticated),
    kcInitialized: readonly(_kcInitialized),
  };
};
